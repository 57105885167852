import {useChatbotContext} from "../../Core/ChatbotContext";
import {isFieldDisabled} from "../../Core/ChatbotHelper";

const Options = ({options}) => {
  const [{user}, dispatch] = useChatbotContext();

  const handleClick = option => {
    dispatch({isChatDisabled: isFieldDisabled(user, option.id)});
    option.handler();
  }

  return (
    <div className="options">
      <div className="options-container">
        {options.map((option) => {
          return (
            <div
              className="option-item"
              onClick={() => handleClick(option)}
              key={option.id}
            >
              {option.name}
            </div>
          );
        })}
      </div>
    </div>
  );
};

export default Options;
