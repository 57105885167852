import React, {useState} from "react";
import {useForm} from "react-hook-form";
import {ConditionallyRender} from "react-util-kit";
import PhoneInput from 'react-phone-input-2';

import "./FindVacancy.css";
import 'react-phone-input-2/lib/style.css';
import {useChatbotContext} from "../../Core/ChatbotContext";
import {SubmitRequest} from "../../Core/ChatbotHelper";

const FindVacancy = ({actionProvider}) => {
    const [{user}, dispatch] = useChatbotContext();
    const [formSend, setMessage] = useState('');
    const [phone, setPhone] = useState('');
    const [sentData, setSentData] = useState(false);

    const {
        register,
        handleSubmit,
        formState: {errors}
    } = useForm();

    const onSubmit = (formData) => {
        setSentData(true)
        SubmitRequest(formData, phone, actionProvider, user, 'training centre')
            .then((userData) => {
                dispatch({user: userData, isChatDisabled: false});
                setMessage(true);
                reset();
            })
            .catch((err) => {
                setMessage(err.toString());
            });
    };

    return (
        <div className="chatBotForm">
            <ConditionallyRender
                ifTrue={formSend}
                show={
                    <div className="succesMessage">Your data has been sent!</div>
                }
                elseShow={
                    <>
                        <div className="option-item-container">
                            <a className="option-item" href='https://careers.jetsoftpro.com/' target="_blank">Vacancies page</a>
                        </div>
                        <form onSubmit={handleSubmit(onSubmit)}>
                            {!user && <>
                                <input
                                  type="text"
                                  placeholder="First Name"
                                  name="firstName"
                                  {...register("firstName", {
                                      required: true,
                                      maxLength: 50,
                                      pattern: /^[a-zA-Z\s]+$/i
                                  })}
                                />
                                {errors?.firstName?.type === "required" && <p>This field is required</p>}
                                {errors?.firstName?.type === "maxLength" && (
                                  <p>LAst Name cannot exceed 50 characters</p>
                                )}
                                {errors?.firstName?.type === "pattern" && (
                                  <p>Alphabetical characters only</p>
                                )}
                                <input
                                  type="text"
                                  placeholder="Last Name"
                                  name="lastName"
                                  {...register("lastName", {
                                      required: true,
                                      maxLength: 50,
                                      pattern: /^[a-zA-Z\s]+$/i
                                  })}
                                />
                                {errors?.lastName?.type === "required" && <p>This field is required</p>}
                                {errors?.lastName?.type === "maxLength" && (
                                  <p>Last Name cannot exceed 50 characters</p>
                                )}
                                {errors?.lastName?.type === "pattern" && (
                                  <p>Alphabetical characters only</p>
                                )}
                                <input
                                  type="text"
                                  placeholder="Your e-mail"
                                  name="email"
                                  {...register("email", {
                                      required: true,

                                  })}/>
                                {errors?.email?.type === "required" && <p>This field is required</p>}
                            </>}
                            {!user?.phone && <>
                                <PhoneInput
                                  country={'us'}
                                  value={phone}
                                  onChange={v => setPhone(v)}
                                  inputProps={{
                                      required: true
                                  }}
                                />
                                {errors?.phone?.type === "required" && <p>This field is required</p>}
                                {errors?.phone?.type === "pattern" && (
                                  <p>Numeric characters only</p>
                                )}
                            </>}
                            <select {...register("payload", {
                                required: true
                            })}>
                                <option value="">Select...</option>
                                <option value="REQ/1438">Embedded Software Developer</option>
                                <option value="REQ/1437">Senior VueJS Developer</option>
                                <option value="REQ/1451">Senior Python Developer</option>
                                <option value="REQ/1441">Senior Perl Developer</option>
                                <option value="REQ/1416">Senior DevOps Engineer</option>
                            </select>
                            {errors?.vacancy?.type === "required" && <p>This field is required</p>}
                            <textarea
                              placeholder="A few words about..."
                              name="content"
                              {...register("desc", {
                                  required: true,
                                  maxLength: 1000
                              })}
                            />
                            {errors?.desc?.type === "required" && <p>This field is required</p>}
                            <div className="submit-button">
                                <input disabled={sentData} type="submit" value="Send to humans"/>
                            </div>
                        </form>
                    </>
                }
            />

        </div>
    );
};

export default FindVacancy;
