import Options from "../Options/Options";

const GeneralOptions = (props) => {
  const options = [
    {
      name: "I need developers",
      handler: props.actionProvider.handleFindDeveloper,
      id: 1
    },
    {
      name: "Vacancies",
      handler: props.actionProvider.handleFindVacancy,
      id: 2
    },
    {
      name: "Training center",
      handler: props.actionProvider.handleFindCourse,
      id: 3
    },
    {
      name: "Ask Jetty",
      handler: props.actionProvider.handleAskBot,
      id: 4
    }
  ];
  return <Options options={options} title="Options" {...props} />;
};

export default GeneralOptions;
