import { createChatBotMessage } from "react-chatbot-kit";

import BotAvatarImage from "../Components/BotAvatar/BotAvatar"
import Header from "../Components/Header/Header";
import GeneralOptions from "../Components/Overview/Overview";
import FindDeveloper from "../Components/FindDeveloper/FindDeveloper.jsx";
import FindVacancy from "../Components/FindVacancy/FindVacancy.jsx";
import FindCourse from "../Components/FindCourse/FindCourse.jsx";
import UserDetails from "../Components/UserDetails/UserDetails";
import {LinkButton} from "../Components/LinkButton/LinkButton";
import UserAvatar from "../Components/UserAvatar/UserAvatar";

const message = createChatBotMessage(
  `Hi there! Jetty is here for you. I connect people with the digital reality!
   I know everything about JSP! Ask me or choose your button!`,
  {
    withAvatar: true,
    delay: 400,
    widget: "overview"
  }
);

const defaultMessage = () => {
  const userData = localStorage.getItem('user_data')
  if (!userData || !userData.date || Date.now() - userData.date < 604800000) {
    localStorage.removeItem('user_data');
    localStorage.removeItem('chat_messages');
    return [message]
  } else {
    return [message]
  }
};

const config = {
  lang: "no",
  botName: "JettyChatBot",
  customStyles: {
    botMessageBox: {
      backgroundColor: "#FFDEBF",
    },
    chatButton: {
      backgroundColor: "#E5E5FF"
    }
  },
  initialMessages: defaultMessage(),
  state: {
    recognitionEnable: true,
  },
  customComponents: {
    // Replaces the default header
    header: (actionProvider) => <Header actionProvider={actionProvider}/>,
    // Replaces the default bot avatar
    botAvatar: (props) => <BotAvatarImage {...props} />,
    userAvatar: (props) => <UserAvatar {...props} />
  },
  widgets: [
    {
      widgetName: "overview",
      widgetFunc: (props) => <GeneralOptions {...props} />,
      mapStateToProps: ["messages"]
    },
    {
      widgetName: 'findDeveloper',
      widgetFunc: ({ actionProvider }) => <FindDeveloper actionProvider={actionProvider} />,
      mapStateToProps: ["messages"]
    },
    {
      widgetName: 'findVacancy',
      widgetFunc: ({ actionProvider }) => <FindVacancy actionProvider={actionProvider} />,
      mapStateToProps: ["messages"]
    },
    {
      widgetName: 'findCourse',
      widgetFunc: ({ actionProvider }) => <FindCourse actionProvider={actionProvider} />,
      mapStateToProps: ["messages"]
    },
    {
      widgetName: 'userDetails',
      widgetFunc: ({ actionProvider }) => <UserDetails actionProvider={actionProvider} />,
      mapStateToProps: ["messages"]
    },
    {
      widgetName: 'linkButton',
      widgetFunc: () => <LinkButton/>,
      mapStateToProps: ["messages"]
    },
  ]
};

export default config;
