import parse from "html-react-parser";

class ActionProvider {

  constructor(createChatBotMessage, setStateFunc, createClientMessage) {
    this.createChatBotMessage = createChatBotMessage;
    this.setState = setStateFunc;
    this.createClientMessage = createClientMessage;
  }

  handleOptions = (options) => {
    let storageData = JSON.parse(localStorage.getItem('chat_messages'));
    const message = this.createChatBotMessage(
      "How can I help you? Below are some possible options.",
      {
        widget: "overview",
        loading: true,
        terminateLoading: true,
        ...options
      }
    );
    localStorage.setItem('chat_messages', JSON.stringify([...storageData, message]));
    this.addMessageToState(message);
  };

  handleFindDeveloper = () => {
    let storageData = JSON.parse(localStorage.getItem('chat_messages'));
    const user = (JSON.parse(localStorage.getItem('user_data')) || null);
    const userNameMessage = user ? `${user.firstName}! ` : '';
    const message = this.createChatBotMessage(
      userNameMessage + "Whatever it might be, JetSoftPro can do it! But my manager says I should not negotiate about money myself. To be honest, sometimes I am confused by the number of zeros...\n" +
      "Anyway, please " + (user?.phone ? "send your request message" : "fill out the contact form") + ", and the humans will get back to you.",
      {
        widget: "findDeveloper",
        loading: true,
        terminateLoading: true,
        withAvatar: true
      }
    );
    localStorage.setItem('chat_messages', JSON.stringify([...storageData, message]));
    this.addMessageToState(message);
  };

  handleFindVacancy = () => {
    let storageData = JSON.parse(localStorage.getItem('chat_messages'));
    const user = (JSON.parse(localStorage.getItem('user_data')) || null);
    const userNameMessage = user?.firstName || '';

    const message = this.createChatBotMessage(
      userNameMessage + 'Aww... hello, my future colleague! You are welcome to check our Vacancies page and send an application or write a few words about yourself and your search here, and I\'m sure we will find the right match.',
      {
        widget: "findVacancy",
        loading: true,
        terminateLoading: true,
        withAvatar: true
      }
    );
    localStorage.setItem('chat_messages', JSON.stringify([...storageData, message]));
    this.addMessageToState(message);
  };

  handleFindCourse = () => {
    const user = (JSON.parse(localStorage.getItem('user_data')) || null);
    let storageData = JSON.parse(localStorage.getItem('chat_messages'));
    const userNameMessage = user ? `${user.firstName}, p` : 'P';
    const defaultMessage = userNameMessage + 'lease fill out the form below, and we will keep you updated about our next courses.';
    const message = this.createChatBotMessage(
      user?.phone ? defaultMessage : `${userNameMessage}lease leave ${!user ? 'your email and ' : ''}phone number and we will tell you about our next courses for developers.`,
      {
        widget: "findCourse",
        loading: true,
        terminateLoading: true,
        withAvatar: true
      }
    );
    localStorage.setItem('chat_messages', JSON.stringify([...storageData, message]));
    this.addMessageToState(message);
  };

  handleLoading = () => {
    const message = this.createChatBotMessage(
      "Wait for my answer...",
      {
        loading: true,
        terminateLoading: true,
      }
    );
    this.addMessageToState(message);
  }

  handleAnyQuestionsMassage = () => {
    const user = (JSON.parse(localStorage.getItem('user_data')) || null);
    let storageData = JSON.parse(localStorage.getItem('chat_messages'));
    const message = this.createChatBotMessage(
      `${user.firstName}, if you have any questions, Jetty will be happy to help! Just type your message or use the voice recognition button.`,
      {
        loading: true,
        terminateLoading: true,
        withAvatar: true
      }
    );
    localStorage.setItem('chat_messages', JSON.stringify([...storageData, message]));
    this.addMessageToState(message);
  };

  handleAskBot = () => {
    const user = JSON.parse(localStorage.getItem('user_data')) || null;

    if (user) {
      this.handleAskQuestion();
    } else {
      this.handleUserDetails();
    }
  };

  handleAskQuestion = option => {
    let storageData = JSON.parse(localStorage.getItem('chat_messages'));
    let user = JSON.parse(localStorage.getItem('user_data'));
    const message = this.createChatBotMessage(
      (option ? `Thank you, ${user.firstName}. `: "") + "Please type your message or use voice recognition button",
      {
        loading: true,
        terminateLoading: true,
        withAvatar: true
      }
    );
    localStorage.setItem('chat_messages', JSON.stringify([...storageData, message]));
    this.addMessageToState(message);
  };

  handleUserDetails = () => {
    let storageData = JSON.parse(localStorage.getItem('chat_messages'));
    const message = this.createChatBotMessage(
        "Please write your Name and Email to continue.",
        {
            widget: "userDetails",
            loading: true,
            terminateLoading: true,
            withAvatar: true
        }
    );
    localStorage.setItem('chat_messages', JSON.stringify([...storageData, message]));
    this.addMessageToState(message);
  };

  handleSuccess = () => {
    let storageData = JSON.parse(localStorage.getItem('chat_messages'));
    const message = this.createChatBotMessage(
      "Whoooa! Humans will be happy and get back to you shortly",
      {
        loading: true,
        terminateLoading: true,
        withAvatar: true
      }
    );
    localStorage.setItem('chat_messages', JSON.stringify([...storageData, message]));
    this.addMessageToState(message);
  };

  handleVoice = (props) => {
    const message = this.createClientMessage(props.message);
    this.addMessageToState(message);
    this.handleAskQuestionTyped(props.message);
  };

  async handleAskQuestionTyped(props) {
    const user = JSON.parse(localStorage.getItem('user_data')) || null;

    if (user) {
      let storageData = JSON.parse(localStorage.getItem('chat_messages'));

      const clientMessage = this.createClientMessage(props);
      localStorage.setItem('chat_messages', JSON.stringify([...storageData, clientMessage]));

      const timer = setTimeout(() => {
        this.handleLoading();
      }, 2000);

      const getAnswer = await fetch('https://prod-bot.jetsoftpro.com/chat', {
        method: 'POST',
        headers: {
          'Accept': 'application/json',
          'Content-Type': 'application/json'
        },
        body: JSON.stringify(
          {
            question: props,
            email: user.email,
            createdAt: new Date()
          }
        )
      });


      const answer = await getAnswer.json();
      clearTimeout(timer);
      const answerToText = answer?.result?.[Object.keys(answer.result)?.[0]];
      let widget = null;

      if (answerToText?.includes('We have 35+ clients and partners and 7+ rewards')) {
        widget = 'linkButton';
      }

      const message = this.createChatBotMessage(
        parse(answerToText || ''),
        {
          widget,
          loading: true,
          terminateLoading: true,
          withAvatar: true
        }
      );

      const messageToSave = this.createChatBotMessage(
        answerToText,
        {
          loading: true,
          terminateLoading: true,
          withAvatar: true
        }
      );

      storageData = JSON.parse(localStorage.getItem('chat_messages'));
      localStorage.setItem('chat_messages', JSON.stringify([...storageData, messageToSave]));
      localStorage.removeItem('saved_message');
      this.addMessageToState(message);
    } else {
      localStorage.setItem('saved_message', props);
      this.handleUserDetails();
    }
  };

  handleClientMessage = message => {
    this.handleAskQuestionTyped(message);
  };

  addMessageToState = (message) => {
    this.setState((state) => ({
      ...state,
      messages: [...state.messages, message]
    }));
  };

  handleClose = () => {
  }
}

export default ActionProvider;
