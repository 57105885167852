import React from "react";
import UserAvatarImage from "../../Assets/user-avatar.svg";
import "./UserAvatar.css";

const UserAvatar = () => {
  return (
    <div className="react-chatbot-kit-user-avatar">
      <div
        className="react-chatbot-kit-user-avatar-container">
        <img alt="UserAvatar" className="userAvatar" src={UserAvatarImage} />
      </div>
    </div>
  );
};

export default UserAvatar;
