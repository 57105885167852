import React, {useState} from 'react';
import { useForm } from "react-hook-form";
import { ConditionallyRender } from "react-util-kit";
import {MessageRequest} from "../../Core/ChatbotHelper";
import {useChatbotContext} from "../../Core/ChatbotContext";

const UserDetails = ({ actionProvider }) => {
  const [{user}, dispatch] = useChatbotContext();
  const [formSend, setMessage] = useState('');
  const [sentData, setSentData] = useState(false);

  const {
    register,
    handleSubmit,
    formState: { errors }
  } = useForm();

  const onSubmit = (formData) => {
    setSentData(true)

    const data = {
      firstName: formData.firstName,
      lastName: formData.lastName,
      email: formData.email,
      date: Date.now(),
    };

    const stringifyData = JSON.stringify(data);

    localStorage.setItem('user_data', stringifyData);

    MessageRequest(data)
      .then(() => {
        // actionProvider.handleSuccess();
        dispatch({user: data, isChatDisabled: false});
        setMessage(true);

        const savedMessage = localStorage.getItem('saved_message');

        if (savedMessage) {
          actionProvider.handleClientMessage(savedMessage);
        } else {
          actionProvider.handleAskQuestion(true);
        }
        reset();
      })
      .catch((err) => {
        setMessage(err.toString());
      });
  };

  return (
    <div className="chatBotForm">
      <ConditionallyRender
        ifTrue={formSend || user}
        show={
          <div className="succesMessage">Your data has been sent!</div>
        }
        elseShow={
          <form onSubmit={handleSubmit(onSubmit)}>
            <input
              type="text"
              placeholder="First Name"
              name="firstName"
              {...register("firstName", {
                required: true,
                maxLength: 50,
                pattern: /^[a-zA-Z\s]+$/i
              })}
            />
            {errors?.firstName?.type === "required" && <p>This field is required</p>}
            {errors?.firstName?.type === "maxLength" && (
              <p>LAst Name cannot exceed 50 characters</p>
            )}
            {errors?.firstName?.type === "pattern" && (
              <p>Alphabetical characters only</p>
            )}
            <input
              type="text"
              placeholder="Last Name"
              name="lastName"
              {...register("lastName", {
                required: true,
                maxLength: 50,
                pattern: /^[a-zA-Z\s]+$/i
              })}
            />
            {errors?.lastName?.type === "required" && <p>This field is required</p>}
            {errors?.lastName?.type === "maxLength" && (
              <p>Last Name cannot exceed 50 characters</p>
            )}
            {errors?.lastName?.type === "pattern" && (
              <p>Alphabetical characters only</p>
            )}
            <input
              type="text"
              placeholder="Your e-mail"
              name="email"
              {...register("email", {
                required: true,

              })} />
            {errors?.email?.type === "required" && <p>This field is required</p>}
            <div className="submit-button">
              <input disabled={sentData} type="submit" value="Send to humans"/>
            </div>
          </form>
        }
      />
    </div>
  );
}

export default UserDetails
