import "./styles.css";
import { useState } from "react";
import Fade from "react-reveal/Fade";
import Chatbot from "react-chatbot-kit";
import config from "./Core/ChatbotConfig";
import MessageParser from "./Core/MessageParser";
import ActionProvider from "./Core/ActionProvider";
import "../node_modules/bootstrap/dist/css/bootstrap.min.css";
import {useChatbotState} from "./Core/ChatbotState";
import {ChatbotContext} from "./Core/ChatbotContext";
import parse from "html-react-parser";

const saveMessages = (messages) => {
  localStorage.setItem('chat_messages', JSON.stringify(messages));
};
const loadMessages = () => {
  const msg = JSON.parse(localStorage.getItem('chat_messages'));
  msg.forEach(item => {
    if (Array.isArray(item?.message)) {
      item.message = item?.message[0];
    }

    item.message = parse(item?.message || '');
  });
  return msg;
}

const validateInput = (input) => input.length > 0;

export default function App() {
  const user = JSON.parse(localStorage.getItem('user_data')) || null;
  const [showBot] = useState(true);
  if (!localStorage.getItem('chat_messages')) localStorage.setItem('chat_messages', JSON.stringify(config.initialMessages));
  const localData = JSON.parse(localStorage.getItem('chat_messages'));
  if(localStorage.getItem('chat_id')) localStorage.setItem('chat_messages', JSON.stringify(localData.filter(i => i.message !== "Please Enter your Name and email" )));

  const [state, setState] = useChatbotState({user});

  return (
    <div className="App">
      {showBot && (
          <ChatbotContext.Provider value={[state, setState]}>
            <Fade big>
            <div className="app-chatbot-container">
              <Chatbot
                config={config}
                placeholderText='Ask me anything'
                messageParser={MessageParser}
                actionProvider={ActionProvider}
                messageHistory={loadMessages()}
                saveMessages={saveMessages}
                validator={validateInput}
                runInitialMessagesWithHistory={true}
              />
              {/*{state?.isChatDisabled && <div className="app-chatbot-disabler"/>}*/}
              {/* <div onClick={() => toggleBot((prev) => !prev)} className="closeButton">x</div> */}
            </div>
          </Fade>
        </ChatbotContext.Provider>
      )}
      {/* <Flip left cascade>
          <div className="chatbotImage chathide" id="chatbotImage">
            <div className="bubble bubble-bottom-left">
                  <div className="jettyBubble">
                      <h2>Jetty</h2>
                      <p>Hi! Let me help you!</p>
                  </div>
            </div>
            <button
                className="app-chatbot-button botImage"
                onClick={() => toggleBot((prev) => !prev)}
            >
                <img src="https://jetsoftpro.com/wp-content/themes/JSP/img/jetty-and-starships/Jetty-hover.gif"/>
            </button>
          </div>
      </Flip> */}
    </div>
  );
}
