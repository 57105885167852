class MessageParser {

  constructor(actionProvider, state) {
    this.actionProvider = actionProvider;
    this.state = state;
  }

  parse(message) {
    // localStorage.setItem('chat_messages', JSON.stringify(message));

    message = message.toLowerCase();

    if (
      message.includes("developer") ||
      message.includes("developers") ||
      message.includes("developing")
    ) {
      return this.actionProvider.handleFindDeveloper({ withAvatar: true });
    }

    if (
      message.includes("vacancy") ||
      message.includes("vacancies") ||
      message.includes("find a job")
    ) {
      return this.actionProvider.handleFindVacancy({ withAvatar: true });
    }

    if (
      message.includes("training") ||
      message.includes("course") ||
      message.includes("junior")
    ) {
      return this.actionProvider.handleFindCourse({ withAvatar: true });
    }

    if (
        message.includes("start") ||
        message.includes("return to start") ||
        message.includes("restart")
    ) {
      return this.actionProvider.handleOptions({ withAvatar: true });
    }

    return this.actionProvider.handleAskQuestionTyped( message );
  }
}

export default MessageParser;
