import {useReducer} from 'react';

export const dataInitialState = {
    user: null,
    isChatDisabled: false,
};

const reducer = (state, payload) => ({...state, ...payload});

export function useChatbotState(initial) {
    const [state, dispatch] = useReducer(reducer, {...dataInitialState, ...initial});
    return [state, dispatch];
}
