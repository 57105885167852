export const SubmitRequest = (formData, phone, actionProvider, user, requestType) => {
    const data = {
      "firstName": user?.firstName || formData?.firstName,
      "lastName": user?.lastName || formData?.lastName,
      "email": user?.email || formData.email,
      "phoneNumber":  user?.phone || phone,
      "requestType": requestType,
      "payload": formData.payload,
      "comment": formData.desc,
      "createdAt": new Date(),
    }

    const userData = {
      firstName: user?.firstName || formData?.firstName,
      lastName: user?.lastName || formData?.lastName,
      email: user?.email || formData.email,
      phone: user?.phone || phone,
      data: user?.date || Date.now()
    };

    const stringifyUser = JSON.stringify(userData);

    localStorage.setItem('user_data', stringifyUser);

    return MessageRequest(data)
        .then(() => {
            actionProvider.handleSuccess();
            setTimeout(() => {
              actionProvider.handleAnyQuestionsMassage();
            }, 1000)
            return userData;
        });
};

export const MessageRequest = (data) => {
    const finalFormEndpoint = 'https://prod-bot.jetsoftpro.com/message';

    return fetch(finalFormEndpoint, {
        method: "POST",
        headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
        },
        body: JSON.stringify({...data, createdAt: new Date(), requestType: data?.requestType || 'question'}),
    })
        .then((response) => {
            if (response.status !== 200) {
                throw new Error(response.statusText);
            }

            return response.json();
        });
};

export const isFieldDisabled = (user, optionId) => {
  switch (optionId) {
    case 1:
      return !user?.phone;
    case 2:
    case 3:
      return true;
    case 4:
      return !user;
  }
}
