import React from "react";
import BotAvatarImage from "../../Assets/jetty-hover.gif";
import "./BotAvatar.css";

const BotAvatar = () => {
    return (
        <div className="react-chatbot-kit-chat-bot-avatar">
            <div
                className="react-chatbot-kit-chat-bot-avatar-container">
                <img alt="BotAvatar" className="botAvatar" src={BotAvatarImage} />
            </div>
        </div>
    );
};

export default BotAvatar;
