import React, { useEffect, useState } from "react";
import "./Header.css";
import useSpeechToText from 'react-hook-speech-to-text';
import micIcon from "../../Assets/mic.svg";
import house from "../../Assets/house.svg";
import close from "../../Assets/close.svg";
import {useChatbotContext} from "../../Core/ChatbotContext";

const Header = ({ actionProvider }) => {
    const [, dispatch] = useChatbotContext();
    const {
        isRecording,
        results,
        startSpeechToText,
        stopSpeechToText,
    } = useSpeechToText({
        continuous: true,
        useLegacyResults: false,
        speechRecognitionProperties: {
            lang: 'en-US',
            interimResults: true // Allows for displaying real-time speech results
        }
    });
    const [, setShowPopup] = useState(false)
    useEffect(() => {
        if (results.length > 0) {
            const transcript = results[results.length - 1].transcript;
            actionProvider.handleVoice({ message: transcript });
        }
    }, [results])
    const handleStart = () => {
        dispatch({isChatDisabled: false});
        actionProvider.handleOptions()
        setShowPopup(false)
    }

    const closeBot = () => {
        window?.parent?.postMessage("CLOSE_BOT", '*');
    }

    return (
      <div className="react-chatbot-kit-chat-header-container">
          <button className="voice-button" onClick={isRecording ? stopSpeechToText : startSpeechToText}>
              <img data-recording={isRecording} src={micIcon} alt="mic"/>
          </button>
          <button className="home-btn" onClick={handleStart}>
              <img src={house} alt="home"/>
          </button>
          <div className="react-chatbot-kit-chat-header">
              Conversation with JettyChatBot
              <button className="close-btn" onClick={() => closeBot()}>
                  <img src={close} alt="close"/>
              </button>
          </div>
      </div>
    );
};

export default Header;
