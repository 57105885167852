import React, {useState} from "react";
import { useForm } from "react-hook-form";
import { ConditionallyRender } from "react-util-kit";
import PhoneInput from 'react-phone-input-2';
import "./FindDeveloper.css";
import 'react-phone-input-2/lib/style.css';
import {useChatbotContext} from "../../Core/ChatbotContext";
import {SubmitRequest} from "../../Core/ChatbotHelper";

const FindDeveloper = ({ actionProvider }) => {
    const [{user}, dispatch] = useChatbotContext();
    const [formSend, setMessage] = useState('');
    const [phone, setPhone] = useState('');
    const [sentData, setSentData] = useState(false);

    const {
        register,
        handleSubmit,
        formState: { errors }
    } = useForm();
    const onSubmit = (formData) => {
        setSentData(true)

        SubmitRequest(formData, phone, actionProvider, user, 'developer')
            .then((userData) => {
                dispatch({user: userData, isChatDisabled: false});
                setMessage(true);
                reset();
            })
            .catch((err) => {
                setMessage(err.toString());
            });
    };

    return !user?.phone && <div className="chatBotForm">
            <ConditionallyRender
                ifTrue={formSend}
                show={
                    <div className="succesMessage">Your data has been sent!</div>
                }
                elseShow={
                  <form onSubmit={handleSubmit(onSubmit)}>
                    {!user &&
                      <>
                        <input
                          type="text"
                          placeholder="First Name"
                          name="firstName"
                          {...register("firstName", {
                            required: true,
                            maxLength: 50,
                            pattern: /^[a-zA-Z\s]+$/i
                          })}
                        />
                        {errors?.firstName?.type === "required" && <p>This field is required</p>}
                        {errors?.firstName?.type === "maxLength" && (
                          <p>LAst Name cannot exceed 50 characters</p>
                        )}
                        {errors?.firstName?.type === "pattern" && (
                          <p>Alphabetical characters only</p>
                        )}
                        <input
                          type="text"
                          placeholder="Last Name"
                          name="lastName"
                          {...register("lastName", {
                            required: true,
                            maxLength: 50,
                            pattern: /^[a-zA-Z\s]+$/i
                          })}
                        />
                        {errors?.lastName?.type === "required" && <p>This field is required</p>}
                        {errors?.lastName?.type === "maxLength" && (
                          <p>Last Name cannot exceed 50 characters</p>
                        )}
                        {errors?.lastName?.type === "pattern" && (
                          <p>Alphabetical characters only</p>
                        )}
                        <input
                          type="text"
                          placeholder="Your e-mail"
                          name="email"
                          {...register("email", {
                            required: true,

                          })} />
                        {errors?.email?.type === "required" && <p>This field is required</p>}
                      </>
                    }
                    <PhoneInput
                      country={'us'}
                      value={phone}
                      onChange={v => setPhone(v)}
                      inputProps={{
                        required: true
                      }}
                    />
                    {errors?.phone?.type === "required" && <p>This field is required</p>}
                    {errors?.phone?.type === "pattern" && (
                      <p>Numeric characters only</p>
                    )}
                    <div className="submit-button">
                      <input disabled={sentData} type="submit" value="Send to humans"/>
                    </div>
                  </form>
                }
            />

    </div>;
};

export default FindDeveloper;
